import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import de l'icône Close
import CheckIcon from '@mui/icons-material/Check';
import { useParams } from "react-router-dom";


const ListFactures = () => {
    const { id } = useParams();
    const [factures, setFactures] = useState([]);

    const fetchFactures = async () => {
        try {
            const response = await axios.get('http://cybax.ylinar.fr:3333/api/informations/facturesprofile/' + id);
            if (response.data.status === 'success') {
                setFactures(response.data.data.facture);
            } else {
                console.error('Erreur lors de la récupération des factures: ', response.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchFactures();
    }, []);

    return (
        <Box sx={{ paddingBottom: 6 }}>
            <Paper elevation={3} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom sx={{ color: '#ffffff'}} mb={5}>
                    Liste des Factures
                </Typography>
                <TableContainer
                    component={Paper}
                    sx={{
                        maxHeight: 250,
                        '&::-webkit-scrollbar': {
                            width: '4px', // Largeur de la scrollbar
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888', // Couleur assombrie de la scrollbar
                            borderRadius: '10px', // Coins arrondis
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: '#555', // Couleur plus foncée au survol
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#1B2635', // Couleur du fond de la scrollbar
                        },
                    }}
                >
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Facture</TableCell>
                                <TableCell align="center">Client</TableCell>
                                <TableCell align="center">Total HT</TableCell>
                                <TableCell align="center">TVA</TableCell>
                                <TableCell align="center">Total TTC</TableCell>
                                <TableCell align="center">Édité</TableCell>
                                <TableCell align="center">Soldé</TableCell>
                                <TableCell align="center">Email Envoyé</TableCell>
                                <TableCell align="center">Date</TableCell>
                                <TableCell align="center">Date Paiement</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {factures.map((row) => (
                                <TableRow
                                    key={row.id}
                                    hover sx={{ '& > *': { borderBottom: 'unset' } }}
                                >
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.name}</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.clientName}</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.totalHt} €</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.tva} €</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.totalTtc} €</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.edited ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.solded ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.mail ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.date}</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.datePaiement}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
};

export default ListFactures;