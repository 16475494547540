import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import LaunchIcon from '@mui/icons-material/Launch';
import CloseIcon from '@mui/icons-material/Close';
import { Bell } from "react-feather";
import { Pagination } from '@mui/material';
import axios from 'axios';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius: '20px',
    p: 4,
};

const styleNotif = {
    display: 'flex',
    flexDirection: 'column',
    p: 5,
    m: 3,
    borderRadius: 2,
    bgcolor: '#171F2C',
};

export default function Modalnotif() {
    const [notif, setNotif] = React.useState([]);
    const [page, setPage] = React.useState(1)
    const [info, setInfo] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleDeleteNotification = async (id) => {
        console.log("ID de la notification à supprimer :", id);
        try {
            const response = await axios.get("http://cybax.ylinar.fr:3333/api/notifications/remove/" + id);

            if (response.data.status === 'success') {
                setNotif(notif.filter(notification => notification.id !== id));
            }
        } catch (error) {
            console.error("Erreur lors de la suppression de la notification", error);
        }
    };

    const fetchNotif = async () => {
        try {
            const response = await axios.post('http://cybax.ylinar.fr:3333/api/notifications/paginate', { page: page })
            if (response.data.status === 'success') {
                setNotif(response.data.data.lignes)
                setInfo(response.data.data.meta)

            } else {
                console.error('Erreur lors de la récupération des notifcations : ', response.data.message);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des notifcations :", error);
        } finally {
        }
    };

    React.useEffect(() => {
        fetchNotif();
    }, [page]);


    return (
        <div>
            <Button onClick={handleOpen}>Show all notifications</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center', p: 3 }}>
                        {notif.length} Notification(s)
                    </Typography>

                    <Box id="modal-modal-description" sx={{ mt: 2, borderRadius: 2, verticalAlign: 'middle' }}>
                        {notif.length > 0 ? (
                            <>
                                {notif.slice(0,4).map((notification, index) => (
                                    <Box key={index} sx={styleNotif}>
                                        <Box sx={{ marginTop: 2, display: 'flex', alignItems: 'center'}}>
                                            {notification.icon === 1 ? <Bell style={{
                                                backgroundColor: 'green',
                                                color: 'white',
                                                borderRadius: "100%",
                                                padding: '6px', 
                                                width: "30px",   
                                                height: "30px"
                                            }} /> : < WarningAmberIcon style={{
                                                backgroundColor: 'red',
                                                color: 'white',
                                                borderRadius: "100%",
                                                padding: '6px', 
                                                width: "30px",   
                                                height: "30px"
                                            }} />}
                                            <span style={{ marginLeft: '15px' }}>{notification.title}</span>
                                            <Box sx={{ color: 'red', ml: 'auto', display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
                                                <CloseIcon onClick={() => handleDeleteNotification(notification.id)} />
                                            </Box>
                                        </Box>
                                        <strong style={{marginLeft: 2, marginTop: 'inherit'}}>{notification.description}</strong>
                                        <a
                                            href='https://www.google.com'
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: 'white', marginLeft: 'auto', textDecoration: 'none' }}
                                        >
                                            <LaunchIcon />
                                        </a>
                                    </Box>
                                ))}
                            </>
                        ) : (
                            <Box sx={{ textAlign: 'center', bgcolor: '#171F2C', p: 5, m: 3, borderRadius: 2 }}>
                                Aucune notification
                            </Box>
                        )}
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', width: "max-content" }}>
                            <Pagination
                                count={info.last_page}
                                variant="outlined"
                                shape="rounded"
                                color="primary"
                                page={page}
                                onChange={(event, value) => {
                                    setPage(value);
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}