import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import de l'icône Close

const ListCommandes = () => {
    const { id } = useParams();
    const [commandes, setCommandes] = useState([]);

    const fetchCommandes = async () => {
        try {
            const response = await axios.get('http://cybax.ylinar.fr:3333/api/informations/commandesprofile/' + id);
            if (response.data.status === 'success') {
                setCommandes(response.data.data.commandes);
            } else {
                console.error('Erreur lors de la récupération des commandes: ', response.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchCommandes();
    },[]);

    return (
        <Box sx={{ paddingBottom: 6 }}>
            <Paper elevation={3} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom sx={{ color: '#ffffff' }} mb={5}>
                    Liste des Commandes
                </Typography>
                <TableContainer
                    component={Paper}
                    sx={{
                        maxHeight: 250,
                        '&::-webkit-scrollbar': {
                            width: '4px', // Largeur de la scrollbar
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888', // Couleur assombrie de la scrollbar
                            borderRadius: '10px', // Coins arrondis
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: '#555', // Couleur plus foncée au survol
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#1B2635', // Couleur du fond de la scrollbar
                        },
                    }}
                >
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                            <TableCell align="center">Statut</TableCell>
                                <TableCell align="center">Commande</TableCell>
                                <TableCell align="center">Provenance</TableCell>
                                <TableCell align="center">Client</TableCell>
                                <TableCell align="center">Référence</TableCell>
                                <TableCell align="center">Description</TableCell>
                                <TableCell align="center">Total HT</TableCell>
                                <TableCell align="center">Créée le</TableCell>
                                <TableCell align="center">Livraison le</TableCell>
                                <TableCell align="center">Commercial</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {commandes.map((row) => (
                                <TableRow
                                    key={row.id}
                                    hover sx={{ '& > *': { borderBottom: 'unset' } }}
                                >
                                    <TableCell sx={{ fontSize: '0.7rem', color: row.statut === 'TOTAL' ? 'green' : (row.statut === 'PARTIAL' ? 'orange' : 'red') }} align="center">
                                        {row.statut === 'TOTAL' ? 'Complète' : (row.statut === 'PARTIAL' ? 'Partielle' : <CloseIcon sx={{ color: 'red' }} />)}
                                    </TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.commandeName}</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.provenance}</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.clientName}</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.reference}</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.description}</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.totalHT} €</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.created}</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.livraison}</TableCell>
                                    <TableCell sx={{ fontSize: '0.7rem' }} align="center">{row.seller}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
};

export default ListCommandes;