import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Snackbar,
  Alert,
  TextField as MuiTextField,
  Typography,
  Paper,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload, Delete as MuiDelete } from "@mui/icons-material";
import { spacing } from "@mui/system";
import axios from 'axios';
import { useParams } from "react-router-dom";
import ListCommandes from "./Modules/ListCommandes";
import ListDevis from "./Modules/ListDevis";
import ListBonsLivraison from "./Modules/ListBon";
import ListFactures from "./Modules/ListFacture";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);
const CloudUpload = styled(MuiCloudUpload)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;

function InformationUtilisateur() {
  const { id } = useParams();

  const [profileData, setProfileData] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [imageSrc, setImageSrc] = useState(null); // Image de profil

  const fetchProfile = async () => {
    try {
      const response = await axios.get(`http://cybax.ylinar.fr:3333/api/profiles/getProfile/` + id);
      setProfileData(response.data.data.lignes);
    } catch (error) {
      console.error("Erreur lors de la récupération du profil:", error);
      setErrorMessage('Erreur lors de la récupération du profil');
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`http://cybax.ylinar.fr:3333/api/profiles/updatebase/` + id, { fullname: profileData.fullname, note: profileData.note });
      console.log(response);
      setSuccessMessage('Profil mis à jour avec succès');
    } catch (error) {
      console.error("Erreur lors de la mise à jour du profil:", error);
      setErrorMessage('Erreur lors de la mise à jour du profil');
    }
  };

  useEffect(() => {
    const storedImage = localStorage.getItem('uploadedImage');
    if (storedImage) {
      setImageSrc(storedImage);
    }
    fetchProfile();
  }, []);

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageSrc(reader.result); // Met à jour l'image de profil
        localStorage.setItem('uploadedImage', reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = () => {
    setImageSrc(null);
    localStorage.removeItem('uploadedImage');
  };

  return (
    <React.Fragment>
      <Divider />
      <form onSubmit={handleUpdateProfile}>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Informations Utilisateur
            </Typography>

            <Grid container spacing={6}>
              <Grid item md={8}>
                <TextField
                  id="name"
                  label="Prenom + Nom"
                  variant="outlined"
                  value={profileData.fullname || ''}
                  fullWidth
                  onChange={(e) => setProfileData({ ...profileData, fullname: e.target.value })}
                  my={2}
                />
                <FormControl fullWidth my={2} variant="outlined">
                  <TextField
                    label="Note interne"
                    id="note"
                    multiline={true}
                    rows={14}
                    variant="outlined"
                    value={profileData.note || ''}
                    inputProps={{ style: { resize: 'both' } }}
                    onChange={(e) => setProfileData({ ...profileData, note: e.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <CenteredContent>
                  <Paper>
                    {imageSrc && <img src={imageSrc} alt="Aperçu" style={{ width: '300px', height: '300px', borderRadius: "50%", objectFit: "cover" }} />}
                  </Paper>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="raised-button-file">
                    <Button variant="contained" color="primary" component="span" sx={{ marginTop: "20px" }}>
                      <CloudUpload mr={2} /> Envoyer
                    </Button>
                    <Typography variant="caption" display="block" gutterBottom>
                      Pour un meilleur résultat, l'image doit être de 1500x1000 pixels
                    </Typography>
                  </label>
                  {imageSrc && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleDeleteImage}
                      startIcon={<MuiDelete />}
                      sx={{ marginTop: "20px", marginLeft: "10px" }}
                    >
                      Supprimer l'image
                    </Button>
                  )}
                </CenteredContent>
              </Grid>
            </Grid>

            <Button type="submit" variant="contained" color="primary">
              Sauvegarder
            </Button>
          </CardContent>
        </Card>
        <Snackbar
          open={!!successMessage}
          autoHideDuration={2000}
          onClose={() => setSuccessMessage('')}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={2000}
          onClose={() => setErrorMessage('')}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      </form>
    </React.Fragment>
  );
}

function InformationPersonnelles() {
  const { id } = useParams();
  const [profileData, setProfileData] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const fetchInformationsPersonnelles = async () => {
    try {
      const response = await axios.get(`http://cybax.ylinar.fr:3333/api/profiles/getInformationPersonnelles/` + id);
      setProfileData(response.data.data.lignes);
    } catch (error) {
      console.error("Erreur lors de la récupération des informations", error);
      setErrorMessage('Erreur lors de la récupération des informations');
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`http://cybax.ylinar.fr:3333/api/profiles/updatepersonelles/` + id, {
        adress: profileData.adress,
        city: profileData.city,
        pays: profileData.pays,
        zipCode: profileData.zipCode,
        phone: profileData.phone,
        email: profileData.email
      });
      setSuccessMessage('Profil mis à jour avec succès');
    } catch (error) {
      console.error("Erreur lors de la mise à jour du profil:", error);
      setErrorMessage('Erreur lors de la mise à jour du profil');
    }
  };

  useEffect(() => {
    fetchInformationsPersonnelles();
  }, []);

  return (
    <form onSubmit={handleUpdateProfile}>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Informations Personnelles
          </Typography>
          <Grid container spacing={7}>
            <Grid item md={12}>
              <TextField
                id="address"
                label="Adresse"
                variant="outlined"
                fullWidth
                value={profileData.adress || ''}
                onChange={(e) => setProfileData({ ...profileData, adress: e.target.value })}
                my={2}
              />
            </Grid>
          </Grid>
          <Grid container spacing={7}>
            <Grid item md={12}>
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                fullWidth
                value={profileData.email || ''}
                onChange={(e) => setProfileData({ ...profileData, email: e.target.value })}
                my={2}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <TextField
                id="city"
                label="Ville"
                variant="outlined"
                fullWidth
                value={profileData.city || ''}
                onChange={(e) => setProfileData({ ...profileData, city: e.target.value })}
                my={2}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="zipCode"
                label="Code Postal"
                variant="outlined"
                fullWidth
                value={profileData.zipCode || ''}
                onChange={(e) => setProfileData({ ...profileData, zipCode: e.target.value })}
                my={2}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <TextField
                id="phone"
                label="Téléphone"
                variant="outlined"
                fullWidth
                value={profileData.phone || ''}
                onChange={(e) => setProfileData({ ...profileData, phone: e.target.value })}
                my={2}
              />
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" color="primary">
            Sauvegarder
          </Button>
        </CardContent>
      </Card>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={2000}
        onClose={() => setSuccessMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={2000}
        onClose={() => setErrorMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </form>
  );
}

function Password() {
  const { id } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChangePassword = async (e) => {
    e.preventDefault();

    const passwordRegex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      setErrorMessage('Le mot de passe doit contenir au moins 8 caractères, une majuscule, un chiffre et un caractère spécial.');
      return;
    }

    try {
      const response = await axios.post(`http://cybax.ylinar.fr:3333/api/profiles/changePassword/` + id, { password: newPassword });
      setSuccessMessage('Mot de passe changé avec succès');
      setErrorMessage('');
    } catch (error) {
      console.error("Erreur lors du changement de mot de passe:", error);
      setErrorMessage('Erreur lors du changement de mot de passe');
    }
  };

  return (
    <form onSubmit={handleChangePassword}>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Changer votre Mot de Passe
          </Typography>
          <TextField
            id="newPassword"
            label="Nouveau Mot de Passe"
            variant="outlined"
            type="password"
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            my={2}
          />
          <Button type="submit" variant="contained" color="primary">
            Sauvegarder
          </Button>
        </CardContent>
      </Card>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={4000}
        onClose={() => setSuccessMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={4000}
        onClose={() => setErrorMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </form>
  );
}

function Frais() {
  const { id } = useParams();
  const [fraisData, setFraisData] = useState([]);
  const [frais, setFrais] = useState({ fraisNote: '', fraisDate: '', fraisMontant: '' });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [ticketImage, setTicketImage] = useState(null);

  const fetchFrais = async () => {
    try {
      const response = await axios.get(`http://cybax.ylinar.fr:3333/api/frais/getFrais/` + id);
      if (response.data && response.data.data) {
        setFraisData(response.data.data.lignes);
      } else {
        throw new Error("Aucune donnée disponible");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des informations", error);
      setErrorMessage('Erreur lors de la récupération des informations');
    }
  };

  const handleAddFrais = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('fraisNote', frais.fraisNote);
    formData.append('fraisDate', frais.fraisDate);
    formData.append('fraisMontant', frais.fraisMontant);
    if (ticketImage) {
      formData.append('fraisImage', ticketImage);
    }

    try {
      const response = await axios.post(`http://cybax.ylinar.fr:3333/api/frais/addFrais/` + id, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        }
      });
      setSuccessMessage('Frais ajoutés avec succès');
      setFrais({ fraisNote: '', fraisDate: '', fraisMontant: '' });
      setTicketImage(null);
      fetchFrais();
    } catch (error) {
      console.error("Erreur lors de l'ajout des frais:", error);
      setErrorMessage('Erreur lors de l\'ajout des frais');
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setTicketImage(selectedFile);
  };

  useEffect(() => {
    fetchFrais();
  }, []);

  return (
    <form onSubmit={handleAddFrais}>
      {/* Formulaire pour ajouter un frais */}
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>Ajouter des Frais</Typography>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <div style={{ flex: "1" }}>
              <TextField
                label="Note"
                value={frais.fraisNote}
                onChange={(e) => setFrais({ ...frais, fraisNote: e.target.value })}
                fullWidth
                style={{ marginBottom: "20px", marginTop: "20px" }}
              />
              <TextField
                label="Date"
                value={frais.fraisDate}
                onChange={(e) => setFrais({ ...frais, fraisDate: e.target.value })}
                fullWidth
                style={{ marginBottom: "20px" }}
              />
              <TextField
                label="Montant"
                value={frais.fraisMontant}
                onChange={(e) => setFrais({ ...frais, fraisMontant: e.target.value })}
                fullWidth
              />
            </div>

            {ticketImage && (
              <div style={{ marginLeft: "20px", padding: "10px" }}>
                <img src={URL.createObjectURL(ticketImage)} alt="Aperçu du ticket" style={{ width: '600px', height: 'auto', borderRadius: "30px" }} />
              </div>
            )}
          </div>

          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file-ticket"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="raised-button-file-ticket">
            <Button variant="contained" color="primary" component="span" sx={{ marginTop: "20px", marginRight: "10px" }}>Télécharger un ticket</Button>
          </label>
          <Button type="submit" variant="contained" color="primary" sx={{ marginTop: "20px" }}>Ajouter</Button>
        </CardContent>
      </Card>

      {/* Notifications de succès et d'erreur */}
      <Snackbar open={!!successMessage} autoHideDuration={2000} onClose={() => setSuccessMessage('')} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity="success" sx={{ width: '100%' }}>{successMessage}</Alert>
      </Snackbar>
      <Snackbar open={!!errorMessage} autoHideDuration={2000} onClose={() => setErrorMessage('')} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity="error" sx={{ width: '100%' }}>{errorMessage}</Alert>
      </Snackbar>

      {/* Liste des frais */}
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>Liste des Frais</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }}>Date</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>Montant</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>Note</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>Ticket</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fraisData && fraisData.length > 0 ? (
                  fraisData.map((frais, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ textAlign: 'center' }}>
                        {frais.fraisDate || 'Date non disponible'}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {frais.fraisMontant ? `${frais.fraisMontant} €` : 'Montant non disponible'}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {frais.fraisNote || 'Note non disponible'}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {frais.fraisImage && (
                          <a
                            href={`http://cybax.ylinar.fr:3333/images/upload/frais/${frais.fraisImage}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button variant="contained" color="primary">Voir le ticket</Button>
                          </a>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                      Aucun frais trouvé
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </form>
  );
}

function Default() {
  return (
    <React.Fragment>
      <Helmet title="Configuration Profil" />
      <Typography variant="h3" gutterBottom display="inline">
        Configuration Utilisateur
      </Typography>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <InformationUtilisateur />
          <InformationPersonnelles />
          <Password />
          <ListDevis />
          <ListCommandes />
          <ListBonsLivraison />
          <ListFactures />
          <Frais />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Default;